import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { HashRouter as Router } from "react-router-dom"
import App from "./App.js"
import "./index.css"
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { msalConfig } from "./config/MsalConfig"

export const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

const rootElement = document.getElementById("root") || document.createElement("div")
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <Router>
      <App pca={msalInstance} />
    </Router>
  </StrictMode>,
)
